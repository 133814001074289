import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as PR from "../prime-modules/index";
import { useDispatch, useSelector } from "react-redux";
import { responsiveOptions } from "../constants";
import { getCatalogsList, getCountriesList } from "../services/api";
import { countryActions } from "../store/country";
import { cartFilterActions } from "../store/cart";
import { affiliateTrackingInfoActions } from "../store/affiliateTracking";
import { useLocation, useNavigate } from "react-router-dom";
import {
  geteSIMsDescription,
  renderDurationIfPlural,
  renderPrice,
  renderSpeed,
  setDataAmount,
  setNumberPrecision,
} from "../utils/reuse";
import { plausible } from "../utils/plausibleTracker";
import MakePayment from "./payment/MakePayment";
import { BundleListLoading } from "./skeletonLoading/BundleListLoading";
import { esimIccidActions } from "../store/esim";
import RoamingCountries from "../shared/components/RoamingCountries";
import { useToast } from "../context/ToastContext";
import { unlimitedPlanUsageAsterisk } from "./helper";

const BundlesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, labels } = useToast();
  const getCartData = useSelector((state) => state.cart.catalogCart);
  const getCountry = useSelector((state) => state.country.country);
  const getIPCountry = useSelector((state) => state.country.IPCountry);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const [loading, setLoading] = useState(false);
  const [countryVal, setCountryVal] = useState(true);
  const [country, setCountry] = useState("");
  const [countryData, setCountryData] = useState("");
  const [countries, setCountries] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogData, setCatalogData] = useState({});
  const [bundleInfoVisible, setBundleInfoVisible] = useState(false);
  const [displayNetworks, setDisplayNetworks] = useState(false);
  const [direction, setDirection] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const filter = "";
  const filterBy = "";
  const page = "";
  const limit = "";
  const queryParams = new URLSearchParams(useLocation().search);
  const afid = queryParams.get("afid");
  const [chackoutFormState, setCheckoutFormState] = useState(false);
  const [doNotSell, setDoNotSell] = useState(false)

  const getCatalogs = useCallback(() => {
    if (countryVal) {
      const getCatalogList = (response) => {
        if (response.result === "SUCCESS") {
          const catalogList =
            response.data && response.data.bundles ? response.data.bundles : [];
            setDoNotSell(response?.data?.doNotSell ?? false)
          if (catalogList.length > 0) {
            let sortedCatalogs = catalogList.sort((a, b) => a.price - b.price);
            sortedCatalogs = sortedCatalogs?.map(b => {
             b.description = geteSIMsDescription(b);
              return b;
            })
            setCatalogs(sortedCatalogs);
          } else {
            showToast('info', 'ESMBND-02')
          }
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg)
        }
        setLoading(false);
      };
      if (country && country !== "") {
        setLoading(true);
        setCatalogs([]);
        const catalogDirection = direction === 1 ? "asc" : "desc";
        const catalogCountry = encodeURIComponent(country);
        const queryParameters =
          "countries=" +
          catalogCountry +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&direction=" +
          catalogDirection +
          "&orderBy=" +
          orderBy +
          "&filter=" +
          filter +
          "&filterBy=" +
          filterBy;
        getCatalogsList(queryParameters, headers, dispatch, getCatalogList);
      }
    }
  }, [countryVal, country, showToast, direction, orderBy, headers, dispatch]);

  const setDataDuration = (duration) => {
    return duration && duration + labels.LBL0254;
  };

  const setDataSpeed = (speed) => {
    return speed && speed.join("/");
  };

  const getNetworks = (countries) => {
    let networks = [];
    if (countries && countries.length > 0) {
      const networksList = countries[0]["networks"];
      if (networksList && networksList.length > 0) {
        networks = networksList;
      }
    }
    return networks;
  };

  const setNetworks = (countries) => {
    const networks = getNetworks(countries);

    return (
      networks &&
      (networks.length === 0 ? (
        <span>N/A</span>
      ) : (
        <span className="networkData">
          {networks.map((n, index) => (
            <span key={index} className="catalogNetworks">
              {n.name} {n.speeds && "(" + setDataSpeed(n.speeds) + ")"}
            </span>
          ))}
        </span>
      ))
    );
  };

  const clearStoreContent = useCallback(() => {
    dispatch(cartFilterActions.deleteCatalogCart());
    dispatch(cartFilterActions.setCatalogCartAmount(0));
  }, [dispatch]);

  const trackCountry = useCallback((countryName) => {
    plausible.trackEvent("country", { props: { country: countryName } });
  }, []);

  const getCountryData = useCallback(() => {
    if (countryData === "") {
      setCountryData(null);
      const getCountryData = (response) => {
        if (response.result === "SUCCESS") {
          const countriesData = response.data ? response.data : [];
          if (countriesData.length > 0) {
            const countriesList = countriesData.map((c) => ({
              name: c.name.split("(")[0].trim(),
              region: c.region,
              iso: c.iso,
            }));
            setCountries(countriesList);
            const findCountry = countriesList.find(
              (c) => c.iso === response.ipcountry
            );
            let defaultCountry =
              findCountry && findCountry.iso
                ? findCountry.iso
                : countriesList[0]["iso"];
            if (getIPCountry !== response.ipcountry) {
              clearStoreContent();
              dispatch(countryActions.setIpCountry(response.ipcountry));
            } else if (getCountry && getCountry.iso) {
              defaultCountry = countriesList.find(
                (c) => c.iso === getCountry.iso
              )
                ? getCountry.iso
                : countriesList[0]["iso"];
            }
            setCountry(defaultCountry);
            const getFilteredCountry = countriesList.find(
              (val) => val.iso === defaultCountry
            );
            trackCountry(getFilteredCountry.name);
            dispatch(countryActions.setCountry(getFilteredCountry));

            setDirection(1);
            setOrderBy("price");
            setCountryData(defaultCountry);
          } else {
            showToast("info", "ESMBND-03")
          }
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg);
        }
      };
      const url = "countries";
      getCountriesList(url, headers, dispatch, getCountryData);
    }
  }, [
    countryData,
    headers,
    dispatch,
    getIPCountry,
    getCountry,
    trackCountry,
    clearStoreContent,
    showToast,
  ]);

  useEffect(() => {
    if (afid) {
      navigate("/");
      dispatch(affiliateTrackingInfoActions.setAffiliateTrackingInfo(afid));
    }
  }, [afid, dispatch, navigate]);

  useEffect(() => {
    getCountryData();
  }, [getCountryData]);

  useEffect(() => {
    getCatalogs();
  }, [getCatalogs]);

  const catalogPurchase = (catalogData) => {
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);

    const getCartObj = { ...getCartData };

    if (getCartObj !== catalogName) {
      const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay,
      };
      dispatch(cartFilterActions.setCatalogCart(setCartData));
      dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
    }

    const catalogCountry = catalogData.countries[0]["country"]["iso"];
    const getFilteredCountry = countries.find((x) => x.iso === catalogCountry);
    dispatch(countryActions.setCountry(getFilteredCountry));
    dispatch(esimIccidActions.deleteEsim());
    setCountry(catalogCountry);
    setCheckoutFormState(true);
  };

  const displayCatalogDetails = (catalogData) => {
    setCatalogData(catalogData);
    setBundleInfoVisible(true);
  };
  const productTemplate = (rowData) => {
    const countryName = rowData.countries.map(countryName => countryName.country.name?.split("(")[0]);
    return (
      <div className="border-1 surface-border plan-box">
        <h3>{rowData.description} {unlimitedPlanUsageAsterisk(rowData?.dataAmountForDisplay, labels)}</h3>
        <div className="flex align-items-center justify-content-between mb-5">
          <h2>{renderPrice(rowData.price)}</h2>
          <i
            className="pi pi-eye"
            title={labels.LBL0016}
            onClick={() => {
              displayCatalogDetails(rowData);
            }}
          ></i>
        </div>
        {/* {rowData.dataAmount && (
          <p className="pi pi-chart-pie">
            <span className="font-semibold">
              {setDataAmount(rowData, labels)}
            </span>
          </p>
        )} */}
        <p className="pi pi-clock">
        <span>{labels.LBL0017} - {renderDurationIfPlural(rowData.duration, labels)} </span>     
          </p>
        {rowData.speed && (
          <p className="pi pi-compass">
            <span>{renderSpeed(rowData.speed)}</span>
          </p>
        )}
        <p className="pi pi-globe">
          <span>{labels.LBL0027} - <RoamingCountries roaming={rowData.roamingEnabled}  countryName={countryName} /> </span>
        </p>
        <PR.Button
          label={labels.LBL0032}
          disabled={doNotSell}
          className="goto-plans-button p-button-rounded"
          onClick={() => catalogPurchase(rowData)}
        />
      </div>
    );
  };

  const onCountryChange = (e) => {
    if (e.value) {
      setCountry(e.value);
      setCountryVal(false);
    }
  };

  const onCountryHide = (e) => {
    if (getCountry.iso !== country) {
      clearStoreContent();
      const getFilteredCountry = countries.find((x) => x.iso === country);
      dispatch(countryActions.setCountry(getFilteredCountry));
      trackCountry(getFilteredCountry.name);
      setCountryVal(true);
    }
  };

  const onHide = () => {
    setBundleInfoVisible(false);
    setCatalogData({});
  };




  return (
    <>
      <div className="country-dropdown">
        <PR.Dropdown
          filter
          value={country}
          options={countries}
          onChange={(e) => onCountryChange(e)}
          onHide={(e) => onCountryHide(e)}
          resetFilterOnHide
          optionLabel="name"
          optionValue="iso"
          placeholder={labels.LBL0023}
        />
      </div>
      <PR.Dialog
        header={labels.LBL0016}
        className="bundle-dialog"
        visible={bundleInfoVisible}
        blockScroll={true}
        draggable={false}
        breakpoints={{ "960px": "85vw" }}
        style={{ width: "38vw" }}
        onHide={onHide}
      >
        <div className="data-item">
          <div className="data-item-header">
            <div className="grid grid-nogutter mt-2 align-items-center">
              <div className="col-12 md:col-9 lg:col-9">
                <h3>
                  <span className="vertical-align-super">
                    {catalogData.description}
                  </span>
                </h3>
              </div>
              <div className="col-12 md:col-3 lg:col-3 text-right">
                {catalogData.price && (
                  <h3 className="color-h3">{renderPrice(catalogData.price)}</h3>
                )}
              </div>
            </div>
          </div>
          <div className="data-item-content">
            <table className="w-12">
              <tbody>
                <tr>
                  <td>{labels.LBL0024}</td>
                  <td className="text-right">{setDataAmount(catalogData, labels)} {unlimitedPlanUsageAsterisk(catalogData?.dataAmountForDisplay, labels)}</td>
                </tr>
                <tr>
                  <td>{labels.LBL0025}</td>
                  <td className="text-right">
                    {setDataDuration(catalogData.duration)}
                  </td>
                </tr>
                {catalogData.speed && (
                  <tr>
                    <td>{labels.LBL0026}</td>
                    <td className="text-right">
                      {setDataSpeed(catalogData.speed)}
                    </td>
                  </tr>
                )}
                <tr
                  className={
                    getNetworks(catalogData.countries).length > 0
                      ? "network-tr"
                      : ""
                  }
                >
                  <td>{labels.LBL0028}</td>
                  {catalogData.geog_type === "Global" ||
                    catalogData.geog_type === "Regional" ? (
                    <td>
                      <span className="roamingData">
                        {labels.LBL0029} (
                        {catalogData.roamingEnabled &&
                          catalogData.roamingEnabled.length}{" "}
                        {labels.LBL0030})
                        <i
                          className="roaming-tooltip pi pi-info-circle info-btn"
                          title={labels.LBL0326}
                          onClick={() => setDisplayNetworks(true)}
                        ></i>
                        <PR.Dialog
                          header={labels.LBL0031}
                          className="roamingColumnsSection bundle-dialog"
                          visible={displayNetworks}
                          blockScroll={true}
                          draggable={false}
                          onHide={() => setDisplayNetworks(false)}
                          style={{ width: "60vw" }}
                          resizable={false} >
                          <div>
                            {catalogData.roamingEnabled &&
                              catalogData.roamingEnabled.map(
                                (column, columnIndex) => (
                                  <div key={columnIndex}>
                                    <p>
                                      <strong>
                                        {column.country && column.country.name
                                          ? column.country.name.replace(" (Special Administrative Region of China)", "")
                                          : ""}
                                      </strong>
                                    </p>
                                    <ul key={columnIndex}>
                                      {column.networks &&
                                        column.networks.map(
                                          (item, itemIndex) => (
                                            <li
                                              key={itemIndex}
                                              className="mb-2"
                                            >
                                              {item.name} (
                                              {item.speeds &&
                                                item.speeds.join(",")}
                                              )
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                )
                              )}
                          </div>
                        </PR.Dialog>
                      </span>
                    </td>
                  ) : (
                    <td>{setNetworks(catalogData.countries)}</td>
                  )}
                </tr>
                <tr className="last-row">
                  <td className="text-left">
                    <PR.Button
                      label={labels.LBL0032}
                      disabled={doNotSell}
                      className="buynow-btn"
                      onClick={() => {
                        catalogPurchase(catalogData);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PR.Dialog>
      { doNotSell && <p className='text-center text-xs err-msg mt-6'><sup className='err-msg'>*</sup> {labels?.LBL0327}</p>}
      <PR.Dialog
        visible={chackoutFormState}
        className="forms-dialog payment-dialog"
        blockScroll={true}
        draggable={false}
        style={{ width: "75%" }}
        onHide={() => setCheckoutFormState(false)}
      >
        <MakePayment setCheckoutFormState={setCheckoutFormState} />
      </PR.Dialog>
      {loading ? (
        BundleListLoading()
      ) : (
        <PR.Carousel
          value={catalogs}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
        />
      )}
    </>
  );
};

export default BundlesList;
